require("./assets/main.css");
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import SequentialEntrance from 'vue-sequential-entrance'
import 'vue-sequential-entrance/vue-sequential-entrance.css'
import AOS from 'aos';
import 'aos/dist/aos.css'
import hideOnScroll from 'hide-on-scroll';

Vue.use(hideOnScroll);

Vue.use(SequentialEntrance);

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App),
  mounted() {
    AOS.init({
      disable: false,
      startEvent: 'DOMContentLoaded',
      duration: 1000,
      easing: 'ease-in-out-cubic',
      once: false,
      disableMutationObserver: false,
    })
    
  },
}).$mount('#app')
