<template>
  <div id="train">
    <v-container class="all">
      <div class="cell">
        <div class="one" data-aos="fade-left">
          <v-img class="tht" :src="img"> </v-img>
        </div>
      </div>
      <div class="cont">
        <div class="twt">
          <h1 data-aos="fade-left">Treinamentos</h1>
          <p data-aos="fade-right">
            <strong>
              Nossos prepostos são qualificados para representar sua empresa em
              uma audiência.</strong
            >
          </p>
          <p data-aos="fade-left">
            O preposto só é habilitado para participar de audiências após passar
            pelos treinamentos cuidadosamente preparados pelo nosso time
            jurídico. Isto para garantir qualidade do serviço prestado por eles.
          </p>
          <p data-aos="fade-right">
            Nossos
            <strong
              >treinamentos envolvem as esferas Cível e Trabalhista</strong
            >
            , entre diversos outros temas pertinentes à atuação do preposto em
            audiências.
          </p>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/Celular.png"),
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Zen+Antique+Soft&display=swap");

h1 {
  font-family: lunchtype22light format("woff2"), serif;
  font-weight:400; 
  color: #9adbf5;
  text-align: center;
  margin: 5%;
}
p {
  font-family: lunchtype22light format("woff2"), serif;
  font-weight:300; 
  color: rgb(0, 0, 0);
  font-size: 1.3em;
  margin-top: 5%;
  text-align: justify;
}
.all {
  display: flex;
  width: 100%;
  height: auto;
}
.cell {
  display: flex;
  height: 100%;
  width: 40%;
  justify-content: center;
  align-items: center;
}

.cont {
  display: flex;
  height: 100%;
  width: 60%;
  justify-content: center;
}
.tht {
  height: 500px;
  width: 250px;
}
.twt {
  width: 100%;
}
.tht {
  height: 500px;
  width: 250px;
}

@media all and (max-width: 1500px) {
  h1 {
    margin-top: 0%;
  }
  p {
    font-size: 1.1em;
  }
  .tht {
    height: 380px;
    width: 200px;
  }
}
@media all and (max-width: 500px) {
 .cell{
   display:none;
 }
 .cont{
   width: 100%;
 }
 .all{
   overflow-y: hidden;
 }
}

</style>