<template>
  <div >
    <v-btn
      color="#9adbf5"
      elevation="2"
      fab
      fixed
      right
      bottom
      x-large
      :href="`https://api.whatsapp.com/send?phone=${phoneNumber}`"
      target="_blank"
    >
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phoneNumber: '5511989182626',
    }
  },
}
</script>