<template>
  <div class="all">
    <v-app>
      <Navbar />
      <Init />
      <WhatsappButton />
      <Product />
      <Cookies/>
      <Video />
      <MarketPlaceSP />
      <We />
      <Training />
      <Toolbar />
      <Products />
      <Faq />
      <Footer />
    </v-app>
  </div>
</template>

<script>
import Toolbar from "./components/Toolbar";
import WhatsappButton from "./components/WhatsappButton";
import Product from "./components/product";
import Init from "./components/init";
import Video from "./components/Video";
import We from "./components/we";
import Training from "./components/training";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Products from "./components/Products";
import Faq from "./components/Faq";
import MarketPlaceSP from "./components/MarketPlaceSP.vue";
import Cookies from "./components/Cookies.vue";

export default {
  name: "App",

  components: {
    Toolbar,
    Video,
    Init,
    Product,
    We,
    Footer,
    Training,
    WhatsappButton,
    Navbar,
    Products,
    Faq,
    MarketPlaceSP,
    Cookies
  },
  created() {
    let referrer = document.referrer;
    console.log("Referenciador: " + referrer);
  },

  data: () => ({
    //
  }),
  methods: {
    isFromGoogle() {
      // Obtém o referenciador da requisição HTTP
      var referrer = document.referrer;
      console.log(referrer);
      // Verifica se o referenciador inclui "google.com"
      if (referrer.includes("whatsapp.com")) {
        console.log("kkkkkkkkkk");
      } else {
        console.log("kkkkkkkkkk2");
      }
    },
  },
};
</script>
<style>
.all {
  overflow-x: hidden;
  font-family: lunchtype22light format("woff2"), serif;
}
</style>
