<template>
  <div class="about-us" id="content">
    <div class="content">
      <div class="text-section">
        <div class="section-title">
          <div class="title">
            <h2 data-aos="fade-down" data-aos-delay="100">
              Estamos no Marketplace do OAB SP! Confira:
            </h2>
          </div>
          <div class="text-content mt-10">
            <div>

              <p data-aos="fade-left" data-aos-delay="100">
                O Marketplace de Lawtechs da OAB SP é o seu portal de entrada para
                o futuro da advocacia. Aqui você encontrará uma ampla gama de
              tecnologias jurídicas inovadoras, que estão redefinindo o modo
              como a prática da advocacia é conduzida. Com o apoio de
              Inteligência Artificial, algoritmos e machine learning, as
              Lawtechs facilitam consultas à legislação, otimizam a organização
              de documentos, aprimoram a relação de clientes e impulsionam os
              processos de gestão.
              
            </p>
            <p data-aos="fade-right" data-aos-delay="100"><b>Veja mais em:</b> <a href="https://marketplace.oabsp.org.br/legal-proxy/">marketplace.oabsp.org.br/legal-proxy/</a></p>
          </div>
          </div>
        </div>
      </div>
      <div class="img">
        <img class="oab-image" data-aos="fade-right" data-aos-delay="100" :src="img" />
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      img: require("@/assets/OAB.png"),
    };
  },
};
</script>
  
  <style scoped>
h2 {
  color: rgb(0, 0, 0);
  font-weight: 400;
}
p {
  color: rgb(0, 0, 0);
  font-size: 1.3em;
  font-weight: 300;
}
.about-us {
  height: auto;
  background: #FDFDFD;
  overflow-x: hidden;
  padding: 40px 0px;
}
.content {
  width: 100%;
  display: flex;
}
.img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
}
.text-section {
  display: flex;
  width: 65%;
  justify-content: center;
}
.section-title {
  width: 70%;
}
.text-content {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
}
.oab-image{
  width: 450px;
  border-radius:15px;
  height: 300px;
}
@media all and (max-device-width: 1500px) {
  .tht {
    height: 380px;
    width: 200px;
  }
  .title {
    margin-top: 5%;
  }
  .text-content {
    margin-top: 5%;
  }
}
@media all and (max-width: 900px) {
  .img {
    display: none;
  }
  .text-content {
    width: 100%;
  }
  .section-title {
    width: 90%;
  }
  .text-section {
    width: 100%;
  }
  .about-us {
    padding: 15px 0px;
    overflow-y: hidden;
  }
  .first-image-card {
    width: 100px;
    height: 100px;
  }
}
</style>