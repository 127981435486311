<template>
  <v-parallax
    height="100"
    :src="img"
  ></v-parallax>
</template>

<script>
export default {
 data() {
    return {
      img: require("@/assets/walking.jpg"),
    };
  },
}
</script>

<style scoped>
.end{
    height:20vh;
    background:rgb(255, 255, 255)
}

</style>