<template>
  <div class="all" id="footer">
    <v-img class="backgrund-image" :src="img" height="20vh">
      <div class="every">
        <div class="stb">
          <div class="titulo">
            <h2>Informações de contato</h2>
          </div>
          <div class="akg">
            <p>
              <v-icon>mdi-whatsapp</v-icon>+55 (11) 9 8918-2626
            </p>

            <p class="ml-5">
              <v-icon>mdi-email</v-icon>atendimento@legalproxy.com.br
            </p>
          </div>
        </div>
        <div class="k404">
          <div>
            <v-img
              :src="img2"
              class="mt-15"
              height="100"
              width="100"
              align="center"
            >
            </v-img>

            <v-img
              :src="img3"
              class="mt-1"
              height="39"
              width="220"
              data-aos="fade-right"
              data-aos-delay="100"
            >
            </v-img>
          </div>
        </div>
      </div>
    </v-img>
    <div class="end">
      {{ new Date().getFullYear() }}, Todos os direitos reservados Legal Proxy
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/contato.png"),
      img2: require("@/assets/logo1 (2).png"),
      img3: require("@/assets/logo1 (1).png"),
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Zen+Antique+Soft&display=swap");

h2 {
  font-family: lunchtype22light format("woff2"), serif;
  font-size: 2em;
  font-weight: normal;
  color: rgb(0, 0, 0);
}
p {
  color: rgb(0, 0, 0);
  font-family: lunchtype22light format("woff2"), serif;
  font-size: 1.4em;
}
.all {
  display: block;
  flex-direction: row;
  background: white;
}
.akg {
  display: flex;
  width: 80%;
  margin-top: 3%;
}
.every {
  display: flex;
  width: 100%;
  height: 40vh;
  align-items: center;
  justify-content: center
}
.stb {
  width: 50%;

  height: 100%;
}
.k404 {
  display: flex;
  width: 25%;
  height: 100%;
  justify-content: center;
}
.titulo {
  margin-top: 5%;
}
.end {
  display: flex;
  justify-content: center;
  height: 5vh;
  align-items: center;
}
@media all and (max-device-width: 1500px) {
  .akg {
    width: 100%;
    margin-top: 5%;
  }
  .all {
    height: 45vh;
  }
  .titulo {
    margin-top: 5%;
  }
  .k404 {
    height: 100%;
    align-items: top;
  }
}
@media all and (max-device-width: 500px) {
  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 1.1em;
  }
  .all {
    height: auto;
  }
  .k404 {
    display: none;
    height: 100%;
    align-items: top;
  }
  .stb {
    width: 100%;
  }
  .akg {
    display: block;
    margin-left: 5%;
    width: 100%;
    margin-top: 3%;
  }
  .titulo {
    margin-top: 5%;
    margin-left: 5%;
  }
}
</style>