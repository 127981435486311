<template>
  <div class="about-us" id="content">
    <div class="content">
      <div class="text-section">
        <div class="section-title">
          <div class="title">
            <h1 data-aos="fade-down" data-aos-delay="100">Quem somos?</h1>
            <h3 class="mt-2" data-aos="fade-right" data-aos-delay="100">
              Somos apaixonados por Direito e Tech
            </h3>
          </div>
          <div class="text-content">
            <p data-aos="fade-left" data-aos-delay="100">
              Através da iniciativa de profissionais com vasta experiência no
              meio jurídico juntamente com pessoas que respiram tecnologia,
              surgiu a ideia de simplificar a busca e o gerenciamento de
              prepostos através da tecnologia. Somos uma legaltech especializada
              na intermediação de prepostos de forma totalmente online e
              automatizada.
            </p>
          </div>
          <div data-aos="fade-left" class="images-box">
            <img class="first-image-card mr-5" src="@/assets/legal-proxy1.jpeg" alt="" />
            <img class="first-image-card mr-5" src="@/assets/legal-proxy2.jpeg" alt="" />
            <img class="first-image-card" src="@/assets/legal-proxy3.jpeg" alt="" />
          </div>
        </div>
      </div>
      <div class="img">
        <v-img
          data-aos="fade-right"
          data-aos-delay="100"
          :src="img"
          height="100%"
        >
        </v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/working.jpg"),
      img2: require("@/assets/logo1 (2).png"),
    };
  },
};
</script>

<style scoped>
h1,
h3 {
  color: rgb(0, 0, 0);
  font-weight: 400;
}
p {
  color: rgb(0, 0, 0);
  font-size: 1.3em;
  font-weight: 300;
}
.about-us {
  height: auto;
  background: #fad97d;
  overflow-x: hidden;
}
.first-image-card{
  width: 140px;
  height: 140px;
}
.content {
  width: 100%;
  display: flex;
}
.img {
  height: 100%;
  width: 35%;
  height: 60vh;
}
.text-section {
  display: flex;
  width: 65%;
  justify-content: center;
}
.section-title {
  width: 70%;
  height: 100%;
}
.text-content {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
}
.title {
  margin-top: 8%;
}
@media all and (max-device-width: 1500px) {
  h1 {
    margin-top: 0%;
  }
  p {
    font-size: 1.1em;
  }
  .tht {
    height: 380px;
    width: 200px;
  }
  .title {
    margin-top: 5%;
  }
  .text-content {
    margin-top: 5%;
  }
}
@media all and (max-width: 900px) {
  .img {
    display: none;
  }
  .text-content {
    width: 100%;
  }
  .section-title {
    width: 90%;
  }
  .text-section {
    width: 100%;
  }
  .about-us {
    padding: 15px 0px;
    height: 500px;
    overflow-y: hidden;
  }
  .first-image-card{
  width: 100px;
  height: 100px;
}
}
</style>