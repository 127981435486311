<template>
  <v-container id="init">
    <div class="all">
      <div class="one" align="center" elevation="5">
        <v-img :src="img" height="100" width="100"></v-img>
        <div class="mt-10">
          <h2 data-aos="fade-down">Preposto já!</h2>
          <p class="mt-7" data-aos="flip-up">
            Com a Legal Proxy você contrata o preposto certo para sua empresa
          </p>
        </div>
      </div>

      <div class="two" align="center" justify-content="center">
        <v-img :src="img2" height="100" width="100"></v-img>
        <div class="mt-10">
          <h2 data-aos="fade-up">Onde precisar</h2>
          <p class="mt-7" data-aos="flip-up">
            Economize com passagens aéreas, hospedagem e alimentação.
          </p>
        </div>
      </div>

      <div class="three" align="center">
        <v-img :src="img3" height="100" width="100"></v-img>
        <div class="mt-10">
          <h2 data-aos="fade-down">Certificação</h2>
          <p class="mt-7" data-aos="flip-up">
            Nossa rede conta com prepostos certificados em representação
            jurídica.
          </p>
        </div>
      </div>

      <div class="four" align="center">
        <v-img :src="img4" height="100" width="100"></v-img>
        <div class="mt-10">
          <h2 data-aos="fade-up">Prático e seguro</h2>
          <p class="mt-7" data-aos="flip-up">
            Todas as transações financeiras são feitas dentro da nossa
            plataforma.
          </p>
        </div>
      </div>
      <v-carousel
        class="carousel"
        cycle
        height="100%"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item>
          <div class="one1" align="center" elevation="5">
            <v-img :src="img" height="100" width="100"></v-img>
            <div class="mt-10">
              <h2>Preposto já!</h2>
              <p class="mt-7">
                Com a Legal Proxy você contrata o preposto certo para sua
                empresa
              </p>
            </div>
          </div>
        </v-carousel-item>
        <v-carousel-item>
          <div class="two1" align="center" justify-content="center">
            <v-img :src="img2" height="100" width="100"></v-img>
            <div class="mt-10">
              <h2>Onde precisar</h2>
              <p class="mt-7">
                Economize com passagens aéreas, hospedagem e alimentação.
              </p>
            </div>
          </div>
        </v-carousel-item>
        <v-carousel-item>
          <div class="three1" align="center">
            <v-img :src="img3" height="100" width="100"></v-img>
            <div class="mt-10">
              <h2>Certificação</h2>
              <p class="mt-7">
                Nossa rede conta com prepostos certificados em representação
                jurídica.
              </p>
            </div>
          </div>
        </v-carousel-item>
        <v-carousel-item>
          <div class="four1" align="center">
            <v-img :src="img4" height="100" width="100"></v-img>
            <div class="mt-10">
              <h2>Prático e seguro</h2>
              <p class="mt-7">
                Todas as transações financeiras são feitas dentro da nossa
                plataforma.
              </p>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/martel.png"),
      img2: require("@/assets/lugar-colocar.png"),
      img3: require("@/assets/certificado.png"),
      img4: require("@/assets/segur.png"),
    };
  },
};
</script>

<style scoped>
h2 {
  font-family: lunchtype22light format("woff2"), serif;
  color: #fad97d;
  font-weight: 700;
}
p {
  font-family: lunchtype22light format("woff2"), serif;
  font-weight: 300;
  color: #000000;
}
.all {
  display: flex;
  width: 100%;
  height: 50vh;
  background: rgba(255, 255, 255, 0.89);
  padding: 3%;
  align-items: center;
}
.one {
  width: 30%;
  margin: 10px;
}
.two {
  width: 30%;
  margin: 10px;
}
.three {
  width: 30%;
  margin: 10px;
}
.four {
  width: 30%;
  margin: 10px;
}
.one1,
.two1,
.three1,
.four1,
.carousel {
  display: none;
}

@media all and (max-width: 1500px) {
  .all {
    height: 65vh;
  }
  @media all and (max-width: 900px) {
    .all {
      height: 40vh;
      overflow: hidden;
    }
    .one,
    .two,
    .three,
    .four {
      display: none;
    }
    .carousel {
      display: flex;
    }

    .one1,
    .two1,
    .three1,
    .four1,
    .carousel {
      display: block;
      width: 100%;
      margin: 10px;
    }
  }
}
</style>