<template>
  <v-container id="products">
    <div class="all">
      <div class="contOne" data-aos="fade-left">
        <v-img :src="img" height="100%" width="100%"> </v-img>
      </div>
      <div class="contTwo">
        <div class="every">
          <div class="ma-5" align="center">
            <h1>Produto</h1>
          </div>
          <v-row class="one" data-aos="fade-down">
            <v-col cols="2" class="icon" align="center">
              <v-img :src="img0" class="image-icon"> </v-img>
            </v-col>
            <v-col class="text">
              <h3>
                Somos uma plataforma digital de
                <strong>intermediação de prepostos</strong> que permite que você
                acompanhe todos os detalhes da atuação do preposto.
              </h3>
            </v-col>
          </v-row>
          <v-row class="two" data-aos="fade-left">
            <v-col class="icon" cols="2" align="center">
              <v-img :src="img2" class="image-icon"> </v-img>
            </v-col>
            <v-col class="text">
              <h3>
                Aliamos <strong>treinamentos jurídicos</strong> voltados para a
                atuação como preposto,
                <strong>tecnologia com geolocalização em tempo real</strong> e
                <strong>seleção inteligent</strong>e de prepostos em uma única
                plataforma simples e intuitiva.
              </h3>
            </v-col>
          </v-row>
          <v-row class="three" data-aos="fade-up">
            <v-col cols="2" class="icon" align="center">
              <v-img :src="img4" class="image-icon"> </v-img>
            </v-col>
            <v-col class="text">
              <h3>
                Tudo isto para trazer segurança, rapidez e qualidade na
                <strong>representação da sua empresa em audiências.</strong>
              </h3>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/walking.jpg"),
      img0: require("@/assets/martel.png"),
      img2: require("@/assets/lugar-colocar.png"),
      img3: require("@/assets/certificado.png"),
      img4: require("@/assets/segur.png"),
    };
  },
};
</script>

<style scoped>
h3,
h1 {
  font-family: lunchtype22light format("woff2"), serif;
  font-weight: 400;
}
.all {
  display: flex;
  height: 70vh;
  background: white;
  overflow-y:hidden ;
}
.contOne {
  width: 30%;
}
.contTwo {
  display: flex;
  width: 70%;
  margin-left: 1%;
}
.one,
.two,
.three {
  width: 100%;
  background: #9adbf5;
  height: 25%;
  margin: 0px 0px 10px !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.image-icon {
  width: 75px;
  height: 75px;
  flex: 0 0 auto;
}
.text {
  display: flex;
  height: 100%;
  width: 80%;
  align-items: center;
}
.every {
  width: 100%;
  height: 100%;
}
@media all and (max-width: 1500px) {
  .all {
    height: 95vh;
  }
}
@media all and (max-width: 900px) {
  h3 {
    font-size: 0.9em;
  }
  .contOne {
    display: none;
  }
  .contTwo {
    width: 100%;
  }
  .icon{
    width: 30%;
    height: 30px;
  }
  .image-icon {
  width: 55px;
  height: 55px;
  flex: 0 0 auto;
}
}
</style>