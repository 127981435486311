<template>
  <v-app-bar class="scroll-nav" fixed inverted-scroll scroll-threshold="400">
    <v-container>
      <div class="content">
        <div class="image-content">
          <div>
            <v-img @click="$vuetify.goTo('#capa')"  :src="img2" height="40" width="40"> </v-img>
          </div>
        </div>
        <div class="options">
          <h3 @click="$vuetify.goTo('#init')">Inicio</h3>
          <h3 @click="$vuetify.goTo('#videos')">Conheça-nos</h3>
          <h3 @click="$vuetify.goTo('#train')">Treinamentos</h3>
          <h3 @click="$vuetify.goTo('#we') ">Quem somos?</h3>
          <h3 @click="$vuetify.goTo('#products')">Produto</h3>
          <h3 @click="$vuetify.goTo('#faqq')">Faq</h3>
          <h3 @click="$vuetify.goTo('#footer')">Contato</h3>
        </div>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      img2: require("@/assets/logo1 (2).png"),
    };
  },
  methods:{

  }
};
</script>

<style scoped>
h3 {
  font-weight:400; 
  margin-left: 25px;
  cursor: pointer;
}
h3:hover {
  color: #fad97d;
}
.content {
  display: flex;
  width: 100%;
  justify-content: right;
}
.options {
  display: flex;
  width: 70%;
}
.image-content {
  display: flex;
  cursor: pointer;
  width:30%;
  justify-content: left;
}
@media all and (max-width: 900px) {
.scroll-nav{
  display:none;
}
}
</style>