<template>
  <footer>
    <cookie-law 
    buttonText="Prosseguir" 
    theme="dark-lime">
      <div slot="message">
        Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua
        experiência em nossos serviços, personalizar publicidade e recomendar
        conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda
        com tal monitoramento.
      </div>
    </cookie-law>
  </footer>
</template>
   
  <script>
import CookieLaw from "vue-cookie-law";
export default {
  components: { CookieLaw },
};
</script>