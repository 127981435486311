<template>
  <div id="capa">
    <v-img id="cover-image" :src="img">
      <div class="mobile-nav">
        <img class="mobile-logo" src="@/assets/logo1 (2).png" alt="" />
      </div>
      <div class="nav">
        <h2 @click="$vuetify.goTo('#init')">Inicio</h2>
        <h2 @click="$vuetify.goTo('#videos')">Conheça-nos</h2>
        <h2 @click="$vuetify.goTo('#train')">Treinamentos</h2>
        <h2 @click="$vuetify.goTo('#we')">Quem somos</h2>
        <h2 @click="$vuetify.goTo('#products')">Produto</h2>
        <h2 @click="$vuetify.goTo('#faq')">Faq</h2>
        <h2 @click="$vuetify.goTo('#footer')">Contato</h2>
      </div>
      <div class="content">
        <div class="text-body">
          <h1>Legal Proxy</h1>

          <p class="mt-5">
            Somos uma legaltech especializada na intermediação de prepostos.
            Aliamos tecnologia a um método inovador a fim de oferecer
            profissionais certificados para atender sua necessidade, otimizando
            custo e tempo
          </p>
          <div class="buttons">
            <v-btn
              class="my-2 mr-2 button-one"
              @click="appLegal"
              large
              rounded
              outlined
              color="#fad97d"
            >
              Sou empresa
            </v-btn>
            <v-btn
              class="my-2 mr-2"
              @click="storeLegal"
              large
              rounded
              outlined
              color="#9adbf5"
            >
              Quero ser preposto
            </v-btn>
          </div>
        </div>
      </div>
    </v-img>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/usingsmart.png"),
    };
  },
  methods: {
    appLegal() {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("discount")) {
        const discount = searchParams.get("discount");
        window.open(
          "https://app.legalproxy.com.br/#/signin" +
            "?discount=" +
            discount
        );
      } else {
        window.open("https://app.legalproxy.com.br/#/signin");
      }
    },
    storeLegal() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.app.legal_proxy"
      );
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 400;
  font-size: var(--fs-1000);
  color: #fff;
}
h2 {
  font-weight: 500;
  font-size: var(--fs-600);
  color: #fff;
  margin-left: 25px;
  cursor: pointer;
}
h2:hover {
  color: #fad97d;
}
p {
  color: #fff;
  font-size: var(--fs-600);
  font-weight: 400;
}
.content {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: justify;
}

.nav {
  margin-top: 2%;
  width: 100%;
  padding-right: 100px;
  display: flex;
  justify-content: right;
}
#cover-image {
  height: 750px;
  width: 100%;
}
.text-body {
  padding: 150px 30px;
  width: 830px;
}
.mobile-logo {
  width: 40px;
  height: 40px;
  display: block;
}
.mobile-nav {
  display: flex;
  position: absolute;
  z-index: 1;
  padding: 20px 30px;
}

@media all and (max-width: 1500px) {
  #cover-image {
    height: 600px;
    width: 100%;
  }
  .text-body {
    padding: 120px 80px;
    width: 830px;
  }
}
@media all and (max-width: 900px) {
  #cover-image {
    height: 700px;
    width: 100%;
  }
  .buttons {
    display: grid;
  }
  .text-body {
    margin-top: 150px;
    text-align: center;
    padding: 32px;
    width: 830px;
  }
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.753);
  }
  .nav {
    display: none;
  }
  .mobile-logo {
    width: 50px;
    height: 50px;
    display: block;
  }
  .mobile-nav {
    display: flex;
    position: absolute;
    z-index: 1;
    padding: 30px;
  }
}
</style>