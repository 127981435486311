<template>
  <div class="video" id="videos">
    <div class="one">
      <h1 data-aos="fade-down" data-aos-delay="200">
        Conheça os nossos serviços!
      </h1>
      <div class="divs"></div>
      <iframe
        data-aos="fade-left"
        class="mr-5 reponsive-video"
        src="https://www.youtube.com/embed/5XfZQ6Oa7P0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        data-aos="fade-right"
        class="reponsive-video"
        src="https://www.youtube.com/embed/PSHBL_Xq930"
        title="Descubra o Legal Proxy!"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Zen+Antique+Soft&display=swap");

h1 {
  font-family: lunchtype22light format("woff2"), serif;
  font-size: 2em;
  font-weight: normal;
  color: #fad97dee;
  text-align: center;
  margin-bottom: 4%;
}
.video {
  width: 100%;
  display: flex;
  background: #000000f5;
  justify-content: center;
}
.reponsive-video{
    width: 600px;
    height: 400px;
}

.one {
  width: 70%;
  padding: 2%;
}
.divs {
  width: 100%;
  border-bottom: 3px solid #9adbf5;
}
@media all and (max-width: 1500px) {
  h1 {
    font-size: 1.7em;
  }
}
@media all and (max-width: 500px) {
  .one {
    width: 100%;
  }
  .video {
    flex-direction: column;
  }
  .reponsive-video{
    width: 400px;
    height: 300px;
  }
}
</style>