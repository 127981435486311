<template>
  <div class="all" id="faqq">
    <v-container>
      <h1 data-aos="fade-left">Perguntas frequentes</h1>
      <div class="every">
        <div class="company" data-aos="fade-up">
          <h2>Empresa</h2>
          <v-expansion-panels flat>
            <v-expansion-panel
              v-for="item in company_questions"
              :key="item.content"
            >
              <v-expansion-panel-header color="#fad97d">
                <p>{{ item.content }}</p>
                <template v-slot:actions>
                  <v-icon class="ml-5" small> mdi-plus </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="#fad97d">
                <p>{{ item.answer }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="proxy" data-aos="fade-right">
          <h2>Proxy</h2>
          <v-expansion-panels flat>
            <v-expansion-panel
              v-for="item in proxy_questions"
              :key="item.content"
            >
              <v-expansion-panel-header color="#fad97d">
                <p>{{ item.content }}</p>
                <template v-slot:actions>
                  <v-icon class="ml-5" small> mdi-plus </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="#fad97d">
                <p>{{ item.answer }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      proxy_questions: [
        {
          content: "Fui reprovado na prova de preposto. Posso refazer o teste?",
          answer:
            "Sim. O futuro PROXIE poderá refazer a prova de certificação de preposto após 15 dias corridos da última prova.",
        },
        {
          content:
            "Realizei a audiência. Em quanto tempo meu crédito estará disponível?",
          answer:
            "Em até 2 dias úteis após a realização da audiência o crédito estará disposto na “carteira” do PROXIE. O acesso a carteira é possível através do aplicativo ou website LEGAL PROXY.",
        },
        {
          content: "Como são distribuídas as audiências para os prepostos?",
          answer:
            "O LEGAL PROXY, por meio de seu sistema, analisa os PROXIES disponíveis, mais bem qualificados e mais próximos geograficamente, para disponibilizar o preposto ideal para o cliente.",
        },
        {
          content:
            "Já concluí meu certificado LEGAL PROXY para preposto, mas ainda não recebi nenhuma audiência. Quando receberei uma solicitação?",
          answer:
            "Este tempo dependerá da quantidade de demandas disponibilizadas por clientes no momento, bem como a quantidade de prepostos cadastrados na plataforma.",
        },
        {
          content:
            "Gostaria de desativar minha conta, sem excluí-la. É possível?",
          answer:
            "Sim. Existem ambas as opções dentro do website ou app LEGAL PROXY, tanto para exclusão, quanto para desativação de conta.",
        },
      ],
      company_questions: [
        {
          content:
            "O preposto selecionado para realizar minha audiência desistiu. O que devo fazer?",
          answer:
            "A plataforma avisará ao CLIENTE sobre a desistência do PROXIE e informará o novo PROXIE mais bem qualificado de acordo com sua geolocalização e avaliações.",
        },
        {
          content:
            "O PROXIE indicado está demorando para aceitar a solicitação. Devo me preocupar?",
          answer:
            "Não. Os PROXIES têm um prazo para aceitar a solicitação de até 24h. Caso não ocorra neste prazo, a plataforma enviará automaticamente um novo convite para outro PROXIE e avisará ao CLIENTE.",
        },
        {
          content:
            "É possível cadastrar uma audiência e solicitar um preposto com crédito insuficiente em conta?",
          answer:
            "Não. Para buscar e selecionar um PROXIE e posterior realização de audiência, é necessário POSSUIR créditos para contratação.",
        },
        {
          content:
            "Preciso trocar o “ponto focal” de uma ou mais empresas cadastradas. É possível?",
          answer:
            "Sim, o administrador da conta de CLIENTE tem total liberdade de incluir ou remover os “pontos focais”",
        },
        {
          content:
            "Tenho muitas demandas para cadastro imediato na plataforma, há alguma maneira de incluí-las de uma única vez?",
          answer:
            "Sim, o LEGAL PROXY disponibiliza para os usuários uma planilha de Excel disponível para download, que deverá ser preenchida conforme os campos já estipulados e, após, realizado o upload em uma das plataformas LEGAL PROXY.",
        },
      ],
    };
  },
};
</script>

<style scoped>
h1 {
  font-family: lunchtype22light format("woff2"), serif;
  font-weight: 400;
  font-size: 2.1em;
  color: rgb(0, 0, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}
p {
  font-family: lunchtype22light format("woff2");
  font-weight: 300;
}
h2 {
  font-family: lunchtype22light format("woff2");
  font-weight: 400;
}
.all {
  display: flex;
  height: 55vh;
  width: 100%;
  background: #fad97d;
  overflow: hidden;
}
.company {
  width: 100%;
}
.proxy {
  width: 100%;
}
.every {
  display: flex;
  height: 100%;
  margin-top: 2%;
}
@media all and (max-width: 1500px) {
  h1 {
    margin-top: 2px;
    margin-bottom: 5px;
  }
  .all {
    height: 100vh;
  }
}
@media all and (max-width: 900px) {
  .all {
    height: auto;
    overflow: hidden;
  }
  .every {
    display: block;
  }
}
</style>